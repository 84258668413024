import { DateTime } from 'ts-luxon';

import { LicenseV3 } from '@shure/cloud/shared/models/devices';

import { DeviceLicenseV3Fragment } from '../graphql/generated/cloud-sys-api';

import { mapLicenseStateFromSysApi } from './map-license-state';
import { mapLicenseSystemCommunicationState } from './map-license-system-communication-state';

export function mapLicenseV3FromSysApi(license: DeviceLicenseV3Fragment | null | undefined): LicenseV3 | undefined {
	if (!license) {
		return undefined;
	}

	return {
		info: license.info.map((license) => ({
			count: license.count,
			expirationDate: license.expirationDate ? DateTime.fromISO(license.expirationDate) : undefined,
			featureName: license.featureName ?? undefined,
			perpetual: !!license.perpetual,
			state: mapLicenseStateFromSysApi(license.state)
		})),
		systemStatus: {
			communicationState: mapLicenseSystemCommunicationState(license.systemStatus.communication.state),
			ownershipMatch: license.systemStatus.ownershipMatch
		}
	};
}
