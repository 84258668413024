import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PrismProgressDotsComponent } from '@shure/prism-angular-components';

import { LoaderComponent } from './loader.component';

@NgModule({
	declarations: [LoaderComponent],
	exports: [LoaderComponent],
	imports: [CommonModule, PrismProgressDotsComponent]
})
export class LoaderModule {}
