<prism-menu id="sh-export-button-menu" *transloco="let t">
	<prism-button [disabled]="buttonDisabled" id="sh-export-button-btn" slot="menu-trigger">
		{{ t('ag-grid.export') }}
		<prism-icon id="sh-export-button-icon" slot="icon-end" icon="arrow_drop_down"></prism-icon>
	</prism-button>
	@for (fileType of exportFileTypes; track fileType) {
		<prism-menu-item [id]="'sh-export-button-menu-item-' + fileType" (click)="exportData(fileType)">{{
			t('ag-grid.' + fileType + 'Export')
		}}</prism-menu-item>
	}
</prism-menu>
