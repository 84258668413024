import { FirmwareUpdateStage, FirmwareUpdateStatus, PropertyPanelDevice } from '@shure/cloud/shared/models/devices';
import { mapDurationFromSysApi } from '@shure/shared/angular/data-access/system-api/mappers';

import { DeviceStatus, PropertyPanelDeviceFragment } from '../graphql/generated/cloud-sys-api';

import { mapBatteryStateFromSysApi } from './map-battery-state';
import { mapFirmwarePackagesFromSysApi } from './map-firmware-packages';
import { mapLicenseInfoFromSysApi } from './map-license-info';
import { mapLicenseInfoV2FromSysApi } from './map-license-info-v2';
import { mapLicenseV3FromSysApi } from './map-license-v3';
import { mapMicStatusFromSysApi } from './map-mic-status';
import {
	mapCompatibilityLevelFromSysApi,
	mapDeviceModelFromSysApi,
	mapFeatureValueFromSysApi,
	mapIpModeFromSysApi,
	mapRfChannelsFromSysApi,
	mapUptimeDurationFromSysApi
} from './utils';

export function mapPropertyPanelDeviceFromSysApi(deviceSysApi: PropertyPanelDeviceFragment): PropertyPanelDevice {
	const {
		id,
		virtual,
		compatibility,
		description: { features: descriptionFeatures, constraints: constraintFeatures },
		interface: { model: deviceModel },
		features: {
			audioChannelCount,
			audioMute,
			audioNetwork,
			availablePackages,
			batteryLevel,
			controlNetwork,
			danteAudioNetwork,
			firmware,
			identify,
			license,
			licenseV2,
			licenseV3,
			micStatus,
			name,
			rfChannels,
			serialNumber,
			updateProgress,
			uptime,
			tags
		}
	} = deviceSysApi;

	const device: PropertyPanelDevice = {
		id: id,
		name: mapFeatureValueFromSysApi(name?.name),
		model: mapDeviceModelFromSysApi(deviceModel ?? ''),
		status: deviceSysApi.status,
		isVirtual: virtual,
		compatibility: mapCompatibilityLevelFromSysApi(compatibility),
		constraints: {
			name: {
				regex: constraintFeatures?.name?.name.pattern ?? ''
			}
		},

		features: {
			audioChannelCount: {
				count: audioChannelCount?.count ?? 0,
				isMissing: !descriptionFeatures.audioChannelCount?.supported
			},
			mute: {
				muted: audioMute?.muted || false,
				isMissing: !descriptionFeatures.audioMute.supported
			},
			audioNetwork: {
				gateway: mapFeatureValueFromSysApi(audioNetwork?.interface.gateway),
				ipAddress: mapFeatureValueFromSysApi(audioNetwork?.interface.ipAddress),
				ipMode: mapIpModeFromSysApi(audioNetwork?.interface.ipMode),
				subnetMask: mapFeatureValueFromSysApi(audioNetwork?.interface.subnetMask),
				macAddress: mapFeatureValueFromSysApi(audioNetwork?.interface.macAddress),
				isMissing: !descriptionFeatures.audioNetwork.supported
			},
			availablePackages: {
				primaryPackages: mapFirmwarePackagesFromSysApi(availablePackages),
				isMissing: !descriptionFeatures.availablePackages.supported
			},
			batteryLevel: {
				percentage: batteryLevel?.percentage ?? 0,
				currentState: mapBatteryStateFromSysApi(batteryLevel?.status.currentState),
				targetState: mapBatteryStateFromSysApi(batteryLevel?.status.targetState),
				timeToTargetState: mapDurationFromSysApi(batteryLevel?.status.timeToTargetState),
				isMissing: !descriptionFeatures.batteryLevel?.supported
			},
			controlNetwork: {
				ipMode: mapIpModeFromSysApi(controlNetwork?.interface.ipMode),
				ipAddress:
					deviceSysApi.status === DeviceStatus.Error
						? mapFeatureValueFromSysApi(deviceSysApi.protocol.dmp?.ipAddress)
						: mapFeatureValueFromSysApi(controlNetwork?.interface.ipAddress),
				subnetMask: mapFeatureValueFromSysApi(controlNetwork?.interface.subnetMask),
				gateway: mapFeatureValueFromSysApi(controlNetwork?.interface.gateway),
				macAddress: mapFeatureValueFromSysApi(controlNetwork?.interface.macAddress),
				isMissing: !descriptionFeatures.controlNetwork.supported
			},
			danteName: {
				danteName: mapFeatureValueFromSysApi(danteAudioNetwork?.name),
				isMissing: !descriptionFeatures.danteAudioNetwork.supported
			},
			firmware: {
				version: mapFeatureValueFromSysApi(firmware?.version),
				valid: firmware?.valid || false,
				isMissing: !descriptionFeatures.firmware.supported
			},
			identify: {
				identifying: identify?.identifying || false,
				isMissing: !descriptionFeatures.identify.supported
			},
			license: {
				info: mapLicenseInfoFromSysApi(license?.info ?? []),
				isMissing: !descriptionFeatures.license?.supported
			},
			licenseV2: {
				info: mapLicenseInfoV2FromSysApi(licenseV2?.info ?? []),
				isMissing: !descriptionFeatures.licenseV2?.supported
			},
			licenseV3: {
				license: mapLicenseV3FromSysApi(licenseV3),
				isMissing: !descriptionFeatures.licenseV3?.supported
			},
			micStatus: {
				status: mapMicStatusFromSysApi(micStatus?.status),
				isMissing: !descriptionFeatures.micStatus?.supported
			},
			// intentionally deviating from the 'isMissing' method since the reboot "feature" isn't really a feature
			// but rather an operation you can perform on a device. Also, there's talk about
			// redoing this "isMissing" concept into something that makes more sense.
			reboot: {
				supported: descriptionFeatures.reboot?.supported ?? false
			},
			rfChannels: {
				channels: mapRfChannelsFromSysApi(rfChannels?.rfChannels ?? []),
				isMissing: !descriptionFeatures.rfChannels?.supported
			},
			serialNumber: {
				serialNumber: mapFeatureValueFromSysApi(serialNumber?.serialNumber),
				isMissing: !descriptionFeatures.serialNumber.supported
			},
			updateProgress: {
				stage: updateProgress?.updateStage ?? FirmwareUpdateStage.Idle,
				status: updateProgress?.updateStatus ?? FirmwareUpdateStatus.Success,
				isMissing: !descriptionFeatures.updateProgress.supported
			},
			uptime: {
				uptime: mapUptimeDurationFromSysApi(uptime?.uptime),
				isMissing: !descriptionFeatures.uptime.supported
			},
			tags: {
				tags: tags?.tags ?? [],
				isMissing: !descriptionFeatures.tags?.supported
			}
		}
	};

	return device;
}
