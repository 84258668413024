import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { AgGridModule, ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { DeviceStatus } from '@shure/cloud/shared/models/devices';
import { DeviceModel } from '@shure/shared/angular/data-access/system-api/models';

import { IdButtonModule } from '../../buttons';
import { DeviceImageModule } from '../../device-image';
import { DeviceStateBarComponent } from '../../device-state-bar';

export interface DeviceNameRendererParent {
	setIdentify(deviceId: string, isIdentifying: boolean): void;
}

interface DeviceNameRendererComponentContext {
	componentParent: DeviceNameRendererParent;
}

export interface DeviceNameRendererParams {
	hideStateBar?: boolean;
}

export interface NameCellData {
	id: string;
	name: string;
	model: DeviceModel;
	status: DeviceStatus;
	isVirtual: boolean;
	canIdentify: boolean;
	isIdentifying: boolean;
	isDisabled: boolean;
}

export type DeviceNameRendererComponentParamsType = ICellRendererParams<
	object,
	NameCellData,
	DeviceNameRendererComponentContext
> &
	DeviceNameRendererParams;

/**
 * ```sh-device-name-renderer``` is a view component for displaying device name component and ID button
 */
@Component({
	selector: 'sh-device-name-renderer',
	templateUrl: './device-name-renderer.component.html',
	styleUrls: ['./device-name-renderer.component.scss'],
	standalone: true,
	imports: [CommonModule, AgGridModule, DeviceStateBarComponent, DeviceImageModule, IdButtonModule, MatIconModule]
})
export class DeviceNameRendererComponent implements ICellRendererAngularComp {
	public params!: DeviceNameRendererComponentParamsType;
	public cellData?: NameCellData;

	public agInit(params: DeviceNameRendererComponentParamsType): void {
		this.update(params);
	}

	public refresh(params: DeviceNameRendererComponentParamsType): boolean {
		this.update(params);
		return true;
	}

	public setIdentify(deviceId: string, isIdentifying: boolean): void {
		this.params.context.componentParent.setIdentify(deviceId, !isIdentifying);
	}

	private update(params: DeviceNameRendererComponentParamsType): void {
		this.params = params;
		this.cellData = params.value ?? undefined;
	}
}
