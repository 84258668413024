<form [formGroup]="formGroup" *transloco="let t">
	<h1 id="sh-prog-profile-dialog-title-h1" mat-dialog-title>
		{{ t('cloud.shure-cloud.home-page.progressive-profile-title') }}
	</h1>
	<mat-dialog-content id="sh-prog-profile-dialog-content">
		<ng-container *ngFor="let formRow of formData">
			<mat-form-field id="sh-prog-profile-dialog-field-{{ formRow.name }}" *ngIf="formRow.type === formType.Text">
				<mat-label id="sh-prog-profile-dialog-{{ formRow.name }}-mat-label">{{ t(formRow.label) }}</mat-label>
				<input
					[placeholder]="t(formRow.label)"
					matInput
					[formControlName]="formRow.name"
					type="text"
					(blur)="onBlur(formRow.name)"
					[mask]="formRow.name === 'workPhone' ? '000-000-0000' : null"
				/>
				@if (formGroup.get('mobilePhone')?.hasError('missingCountryCode')) {
					<mat-error>
						{{ t('cloud.shared.error-labels.country-code-missing') }}
					</mat-error>
				}
				@if (formGroup.get('mobilePhone')?.hasError('invalidCharacters')) {
					<mat-error *ngIf="formGroup.get('mobilePhone')?.hasError('invalidCharacters')">
						{{ t('cloud.shared.error-labels.mobile-number-contains-invalid-characters') }}
					</mat-error>
				}
				@if (formGroup.get('mobilePhone')?.hasError('invalidLength')) {
					<mat-error>
						{{ t('cloud.shared.error-labels.mobile-number-length') }}
					</mat-error>
				}
				@if (formGroup.get('mobilePhone')?.hasError('invalidMobileNumber')) {
					<mat-error>
						{{ t('cloud.shared.error-labels.invalid-mobile-number') }}
					</mat-error>
				}
			</mat-form-field>
			<mat-form-field
				id="sh-prog-profile-dialog-field-{{ formRow.name }}"
				*ngIf="formRow.type === formType.Select"
			>
				<mat-label id="sh-prog-profile-dialog-{{ formRow.name }}-mat-label">{{ t(formRow.label) }}</mat-label>
				<mat-select
					id="sh-prog-profile-dialog-{{ formRow.name }}-mat-select"
					matNativeControl
					[placeholder]="t(formRow.label)"
					[formControlName]="formRow.name"
					(selectionChange)="formRow.name === 'segment' ? segmentSelected($event) : ''"
				>
					<mat-option
						*ngFor="let dataValue of formRow.data"
						[value]="dataValue['value']"
						class="sh-mat-select-option"
					>
						{{ t(dataValue['label']) }}
					</mat-option>
				</mat-select>
				<mat-error *ngIf="!formGroup.controls[formRow.name].valid && formGroup.controls[formRow.name].touched">
					{{ t('cloud.shure-cloud.error-labels.invalid-value') }}
				</mat-error>
			</mat-form-field>
			<div *ngIf="formRow.type === formType.Checkbox" class="sh-prog-profile-dialog-checkbox-div">
				<mat-label *ngIf="formRow.name !== 'tc'" id="sh-prog-profile-dialog-{{ formRow.name }}-mat-label">{{
					t(formRow.label)
				}}</mat-label>
				<ng-container *ngIf="formRow.name === 'relationToShure'">
					<div [formGroupName]="formRow.name">
						<div *ngFor="let option of relationshipOptions">
							<mat-checkbox [formControlName]="option.name">{{ t(option.label) }}</mat-checkbox>
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="formRow.name === 'interests'">
					<div [formGroupName]="formRow.name">
						<div *ngFor="let option of interestsOptions">
							<mat-checkbox [formControlName]="option.name">{{ t(option.label) }}</mat-checkbox>
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="formRow.name === 'tc'">
					<div [formGroupName]="formRow.name">
						<mat-checkbox [formControlName]="'isTcAccepted'"
							>{{ t('cloud.shure-cloud.terms-and-conditions.terms-text') }}
							<a
								id="sh-progressive-dialog-tc-link"
								href="https://www.shure.com/en-US/meta/legal/cloud-application-terms"
								target="_blank"
								>{{ t('cloud.shure-cloud.terms-and-conditions.terms-link') }}</a
							></mat-checkbox
						>
					</div>
				</ng-container>
			</div>
		</ng-container>
	</mat-dialog-content>
	<div class="sh-button-list">
		<button
			[disabled]="formGroup.invalid"
			mat-button
			color="accent"
			(click)="onSubmit()"
			id="sh-prog-profile-dialog-continue-button"
		>
			{{ t('cloud.shared.button-text.continue') }}
		</button>
	</div>
</form>
