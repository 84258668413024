import { Injectable, signal } from '@angular/core';
import {
	BehaviorSubject,
	Observable,
	Subject,
	Subscriber,
	Subscription,
	filter,
	from,
	map,
	merge,
	take,
	takeUntil,
	tap
} from 'rxjs';

import { ApolloCacheManager } from '@shure/cloud/shared/apollo';
import { OktaInterfaceService, monitorLoginState } from '@shure/cloud/shared/okta/data-access';
import { ILogger } from '@shure/shared/angular/utils/logging';

import { DeviceDiscoveryApiService } from '../api/device-discovery-api.service';

import {
	DiscoveredDevicesQueryGQL,
	DiscoveredDevicesQueryOpResult,
	DiscoveredDevicesSubscriptionGQL,
	DiscoveryDeviceFragment
} from './graphql/generated/cloud-sys-api';

export const DISCOVERED_DEVICES_QUERY_PAGE_SIZE = 50;

export interface DeviceDiscoveryEvent {
	added?: DiscoveryDeviceFragment;
	updated?: DiscoveryDeviceFragment;
	removed?: string;
}

@Injectable({ providedIn: 'root' })
export class SysApiDeviceDiscoveryApiService implements DeviceDiscoveryApiService {
	// signal to help with Device Loading Indicator
	public readonly deviceDiscoveryInProgress = signal(false);
	public readonly numDiscoveredDevices = signal(-1);
	//
	private destroy$ = new Subject<void>();
	private readonly logger: ILogger;

	private deviceAddedInternal$ = new Subject<string>();
	private deviceRemovedInternal$ = new Subject<string>();
	private discoveredDevicesMap = new Map<string, DiscoveryDeviceFragment>();
	private discoveredDevicesInternal$ = new BehaviorSubject<DiscoveryDeviceFragment[]>([]);

	private _discoveryEvents$ = new Subject<DeviceDiscoveryEvent>();

	private paginatedQueryEndCursor$: Subject<string | null> | undefined = undefined;

	constructor(
		logger: ILogger,
		private readonly discoveredDevicesQueryGQL: DiscoveredDevicesQueryGQL,
		private readonly discoveredDevicesSubscriptionGQL: DiscoveredDevicesSubscriptionGQL,
		private readonly oktaService: OktaInterfaceService,
		private readonly apolloCacheManager: ApolloCacheManager
	) {
		this.logger = logger.createScopedLogger('DaiDeviceDiscoveryService');

		monitorLoginState(this.oktaService, {
			onLogIn: this.initService.bind(this),
			onLogOut: this.suspendService.bind(this)
		});
	}

	public deviceAdded$(): Observable<string> {
		return this.deviceAddedInternal$.asObservable();
	}

	public deviceRemoved$(): Observable<string> {
		return this.deviceRemovedInternal$.asObservable();
	}

	public discoveryEvents$(): Observable<DeviceDiscoveryEvent> {
		return merge(
			this._discoveryEvents$,
			from([...this.discoveredDevicesMap.values()].map((device) => ({ added: device })))
		);
	}

	public discoveredDevicesCount$(): Observable<number> {
		return this.discoveredDevicesInternal$.pipe(map((devices) => devices.length));
	}

	public getDiscoveredDevicesByQuery$<ElementType extends { id: string }>(
		elementQueryFunction: (id: string) => Observable<ElementType>,
		elementFilterFunction?: (element: ElementType) => boolean
	): Observable<ElementType[]> {
		return new Observable((observer: Subscriber<ElementType[]>) => {
			observer.next([]); // Start by emitting empty array in case no devices that pass the filter exist
			try {
				const returnedDevices = new Map<string, ElementType>();
				const queryHandlers = new Map<string, Subscription>();
				this.discoveryEvents$().subscribe((e) => {
					if ('added' in e && e.added?.id) {
						const queryHandler = elementQueryFunction(e.added?.id)
							.pipe(filter((d) => (elementFilterFunction ? elementFilterFunction(d) : true)))
							.subscribe((d) => {
								returnedDevices.set(d.id, d);
								observer.next([...returnedDevices.values()]);
							});
						queryHandlers.set(e.added?.id, queryHandler);
					} else if ('removed' in e && e.removed) {
						const queryHandler = queryHandlers.get(e.removed);
						if (queryHandler) {
							queryHandler.unsubscribe();
							queryHandlers.delete(e.removed);
						}
						returnedDevices.delete(e.removed);
						observer.next([...returnedDevices.values()]);
					}
				});

				// finalizer method... to automatically unsbubscribe from all queries
				// when/if no one is subscribe to the Observable.
				observer.add(() => {
					queryHandlers.forEach((queryHandler) => queryHandler.unsubscribe());
				});
			} catch {
				observer.error();
			}
		});
	}

	private initService(): void {
		this.logger.information('initService', 'user logged in, initializing service');

		// we need a new destroy$ subject since it is "completed" when the user logs out.
		this.destroy$ = new Subject();

		this.discoveredDevicesMap.clear();
		this.deviceDiscoveryInProgress.set(true);
		this.emitDiscoveredDevices();
		this.subscribeDiscoveredDevices();

		this.paginatedQueryEndCursor$ = new Subject<string | null>();
		this.paginatedQueryEndCursor$.subscribe({
			next: (endCursor) => {
				this.queryDiscoveredDevicesNetwork(endCursor);
			}
		});
		this.paginatedQueryEndCursor$.next(null); // trigger the initial query;
	}

	private suspendService(): void {
		this.logger.information('suspendService', 'user logged out, suspending service');
		this.discoveredDevicesMap.clear();
		this.emitDiscoveredDevices();
		this.destroy$.next();
		this.destroy$.complete();
		this.paginatedQueryEndCursor$?.complete();
		this.deviceDiscoveryInProgress.set(false);
	}

	private handleDeviceAdded(device: DiscoveryDeviceFragment): void {
		this.logger.information('handleDeviceAdded', '', JSON.stringify({ device }));

		this._discoveryEvents$.next({ added: device });
		this.deviceAddedInternal$.next(device.id);
		this.discoveredDevicesMap.set(device.id, device);
		this.emitDiscoveredDevices();
	}

	private handleDeviceRemoved(id: string): void {
		this.logger.information('handleDeviceRemoved', '', { id });

		this._discoveryEvents$.next({ removed: id });
		this.deviceRemovedInternal$.next(id);
		this.discoveredDevicesMap.delete(id);
		this.emitDiscoveredDevices();

		// remove the entry from the apollo cache. The only other way it would
		// get removed is the app refreshing or closing down.
		this.apolloCacheManager.getApolloCache('sysApi')?.evict({ id: `Device:${id}` });
	}

	private emitDiscoveredDevices(): void {
		this.discoveredDevicesInternal$.next([...this.discoveredDevicesMap.values()]);
		this.numDiscoveredDevices.set(this.discoveredDevicesMap.size);
	}

	private subscribeDiscoveredDevices(): void {
		this.discoveredDevicesSubscriptionGQL
			.subscribe(
				{},
				{
					errorPolicy: 'ignore',
					fetchPolicy: 'network-only' //  always fetch from network, then store in cache
				}
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (change) => {
					if (change.data) {
						if ('added' in change.data.discoveredDevices) {
							this.handleDeviceAdded(change.data.discoveredDevices.added);
						} else if ('removed' in change.data.discoveredDevices) {
							this.handleDeviceRemoved(change.data.discoveredDevices.removed);
						}
					}
				},

				complete: () => {
					this.logger.warning('subscribeDiscoveredDevices', 'completed');
				},

				error: (err) => {
					setTimeout(() => {
						this.logger.error('subscribeDiscoveredDevices', 'error', JSON.stringify({ err }));
						this.subscribeDiscoveredDevices();
					}, 10000);
				}
			});
	}

	private queryDiscoveredDevicesNetwork(afterCursor: string | null): void {
		this.discoveredDevicesQueryGQL
			.fetch(
				{
					first: DISCOVERED_DEVICES_QUERY_PAGE_SIZE,
					after: afterCursor,
					deviceModels: []
				},
				{ fetchPolicy: 'network-only' }
			)
			.pipe(
				take(1),
				tap((devices) => this.processDiscoveredDevicesQueryResult(devices.data))
			)
			.subscribe();
	}

	private processDiscoveredDevicesQueryResult(devices: DiscoveredDevicesQueryOpResult): void {
		const { hasNextPage, endCursor } = devices.discoveredDevicesConnection.pageInfo;

		// if there's more data on the server, trigger the next query.
		if (hasNextPage && endCursor && endCursor.length !== 0) {
			this.paginatedQueryEndCursor$?.next(endCursor);
		} else {
			this.deviceDiscoveryInProgress.set(false);
		}
		devices.discoveredDevicesConnection.edges
			.map((d) => d.node)
			.filter((d): d is DiscoveryDeviceFragment => !!d)
			.forEach((d) => this.handleDeviceAdded(d));
	}
}
