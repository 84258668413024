// eslint-disable-next-line max-classes-per-file
import {
	Component,
	Input,
	HostBinding,
	ChangeDetectionStrategy,
	OnInit,
	OnDestroy,
	ChangeDetectorRef,
	AfterContentChecked
} from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subject, Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { SecnavLayoutStateFacade } from '../+state/secnav-layout-state.facade';
import { SidenavLayoutStateFacade } from '../+state/sidenav-layout-state.facade';

export enum MediaAlias {
	XS = 'xs',
	SM = 'sm',
	MD = 'md',
	LG = 'lg'
}

export type SidenavMode = 'over' | 'push' | 'side';

/*export interface ISidenavLayoutConfig {
	closedAlias?: MediaAlias[];
}*/

@Component({
	selector: 'sh-sidenav-layout',
	templateUrl: './sidenav-layout.component.html',
	styleUrls: ['./sidenav-layout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavLayoutComponent implements OnInit, OnDestroy, AfterContentChecked {
	@Input() public closedMode: SidenavMode = 'over';
	@Input() public isCloseButtonEnabled = true;
	@Input() public closedAlias: MediaAlias[] = [MediaAlias.XS, MediaAlias.SM];
	public opened$!: Observable<boolean>;
	public viewMode: SidenavMode = 'side';
	public isSecDrawerOpen = false;

	@Input() public set mode(m: SidenavMode) {
		this.viewMode = m;
		this.openedMode = m;
	}

	private unsubscribe$ = new Subject<void>();
	private openedMode: SidenavMode = 'side';

	constructor(
		private media: MediaObserver,
		private cdr: ChangeDetectorRef,
		private layoutStateFacade: SidenavLayoutStateFacade,
		private secLayoutStateFacade: SecnavLayoutStateFacade
	) {}

	public ngOnInit(): void {
		this.media
			.asObservable()
			.pipe(
				filter((changes: MediaChange[]) => changes.length > 0),
				map((changes: MediaChange[]) => changes[0]),
				takeUntil(this.unsubscribe$)
			)
			.subscribe((change) => {
				if (this.closedAlias) {
					const isInClosedMode = (<string[]>this.closedAlias).includes(change.mqAlias);

					if (isInClosedMode) {
						this.layoutStateFacade.close();
						this.viewMode = this.closedMode;
						this.cdr.detectChanges();
					} else {
						this.layoutStateFacade.open();
						this.viewMode = this.openedMode;
						this.cdr.detectChanges();
					}
				}
			});
		this.opened$ = this.layoutStateFacade.isOpen$;
		this.secLayoutStateFacade.isOpen$.pipe(takeUntil(this.unsubscribe$)).subscribe((isOpen) => {
			this.isSecDrawerOpen = isOpen;
			this.cdr.detectChanges();
		});
	}

	public ngAfterContentChecked(): void {
		window.dispatchEvent(new Event('resize'));
	}

	public ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	public close(): void {
		if (this.isSecDrawerOpen) {
			this.secLayoutStateFacade.close();
		}
		this.layoutStateFacade.close();
	}
}

@Component({
	selector: 'sh-sidenav-layout-topbanner',
	template: '<ng-content></ng-content>',
	styleUrls: ['../shared/layout-topbanner.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavLayoutTopBannerComponent {}

@Component({
	selector: 'sh-sidenav-layout-sidenav',
	template: '<ng-content></ng-content>',
	styleUrls: ['./sidenav-layout-sidenav.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavLayoutSidenavComponent {}

@Component({
	selector: 'sh-sidenav-layout-content',
	template: '<ng-content></ng-content>',
	styleUrls: ['./sidenav-layout-content.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavLayoutContentComponent {}

@Component({
	selector: 'sh-sidenav-layout-footer',
	templateUrl: '../shared/layout-footer.html',
	styleUrls: ['../shared/layout-footer.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavLayoutFooterComponent {
	@Input() public logoPath!: string;
	@HostBinding('class.mat-elevation-z4') public elevationCssClass = true;
}
