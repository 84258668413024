import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import { OktaAuthGuard, OktaAuthModule, OktaCallbackComponent } from '@okta/okta-angular';

import { maintenanceGuard } from '@shure/cloud/shared/maintenance/utils';
import { oktaSessionGuard } from '@shure/cloud/shared/okta/data-access';
import { SigninGuard, SignInComponent } from '@shure/cloud/shared/okta/feature-okta-signin';

import { AppShellComponent } from '../components/app-shell/app-shell.component';

const appRoutes: Route[] = [
	{
		path: 'signin',
		component: SignInComponent,
		canActivate: [SigninGuard]
	},
	{
		path: 'signin/callback',
		component: OktaCallbackComponent
	},
	{
		path: 'maintenance',
		loadChildren: () =>
			import('@shure/cloud/shared/maintenance/feature-maintenance').then((m) => m.MaintenanceModule),
		canActivate: [maintenanceGuard('maintenanceRoute', 'devices'), oktaSessionGuard]
	},
	{
		path: '',
		component: AppShellComponent,
		canActivateChild: [maintenanceGuard('nonMaintenanceRoute', 'maintenance')],
		children: [
			{
				path: 'devices',
				loadChildren: () =>
					import('@shure/cloud/device-management/devices/feature-inventory').then(
						(m) => m.InventoryMainModule
					),
				canActivate: [OktaAuthGuard, oktaSessionGuard]
			},
			{
				path: 'pending-devices',
				loadChildren: () =>
					import('@shure/cloud/device-management/devices/feature-pending').then(
						(m) => m.PendingDevicesModule
					),
				canActivate: [OktaAuthGuard, oktaSessionGuard]
			},
			{
				path: 'account',
				loadComponent: () =>
					import('../components/my-account/my-account.component').then((m) => m.MyAccountComponent),
				canActivate: [OktaAuthGuard, oktaSessionGuard]
			},
			{
				path: '**',
				redirectTo: 'devices',
				pathMatch: 'full'
			}
		]
	},
	// ** is the "page not found" route.
	// Try to go to /devices. If the user isn't logged in, the auth guard
	// will redirect them to the Signin Page.
	{
		path: '**',
		redirectTo: 'devices',
		pathMatch: 'full'
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(appRoutes, {
			initialNavigation: 'enabledBlocking',
			useHash: false // must be false for okta signin/callback to route.
		}),
		OktaAuthModule
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
