<div [ngClass]="{ 'sh-header-toolbar-breadcrumb-container': breadCrumbs().length }">
	<mat-toolbar
		color="primary"
		[ngClass]="{ 'sh-header-toolbar-breadcrumb': breadCrumbs().length }"
		id="sh-header-toolbar-info"
		*transloco="let t"
	>
		<div id="sh-header-toolbar-left-content-div">
			<ng-container *ngIf="showSidenavToggleButton && isSmallDevice()">
				<button id="sh-header-toolbar-menu-button" mat-icon-button (click)="onSidenavToggle()">
					<mat-icon id="sh-header-toolbar-menu-icon" fontSet="prism-mat" fontIcon="menu"></mat-icon>
				</button>
			</ng-container>
			<span id="sh-header-toolbar-secondary-nav-title">{{ getHeader() }}</span>
			<sh-breadcrumbs
				*ngIf="breadCrumbs().length && showBreadCrumbs"
				[breadcrumbs]="breadCrumbs()"
				(breadcrumbClick)="breadCrumbRedirect($event)"
			></sh-breadcrumbs>
		</div>
		<div id="sh-header-toolbar-right-content-div">
			<div id="sh-header-toolbar-feature-button-div" *ngIf="showFeatureButton">
				<button
					mat-button
					color="accent"
					id="sh-header-toolbar-feature-request-button"
					(click)="openFeatureRequestDialog()"
				>
					{{ t('cloud.shared.header.feature-request') }}
				</button>
			</div>
			<ng-container #bellIconContainer></ng-container>
			<div id="sh-header-toolbar-profile-container-div" [attr.profileColor]="colorIndex">
				<ng-container *ngIf="(isImageRemoved$ | async) === false; else profileChar">
					<img
						id="sh-header-toolbar-profile-img"
						[src]="getImageUrl$ | async"
						[matMenuTriggerFor]="menu"
						(load)="onImageLoad()"
						[style.opacity]="imageOpacity"
						(error)="onImageLoadError($event)"
					/>
				</ng-container>

				<ng-template #profileChar>
					<div id="sh-header-toolbar-profile-char-div" [matMenuTriggerFor]="menu">
						{{ profileChars | async | uppercase }}
					</div>
				</ng-template>
			</div>
		</div>

		<mat-menu #menu="matMenu" id="sh-header-toolbar-user-menu">
			<button mat-menu-item [routerLink]="'/account'" id="sh-header-toolbar-button-account">
				<span>{{ t('cloud.shared.header.my-account') }}</span>
			</button>
			<ng-container *ngIf="showNotifications">
				<mat-divider></mat-divider>
				<button
					mat-menu-item
					(click)="navigateToNotificationsList()"
					id="sh-header-toolbar-button-notifications"
				>
					<span>{{ t('cloud.shared.notification.message-center') }}</span>
				</button>
			</ng-container>
			<mat-divider></mat-divider>
			<button mat-menu-item (click)="navigateToShureIdFaq()" id="sh-header-toolbar-button-faq">
				<span>{{ t('cloud.shared.header.my-shure-id-faq') }}</span>
			</button>
			<mat-divider></mat-divider>
			<button mat-menu-item (click)="logout()" id="sh-header-toolbar-button-logout">
				<span id="sh-header-toolbar-menu-sign-out">{{ t('cloud.shared.header.sign-out-shure-id') }} </span>
			</button>
		</mat-menu>
	</mat-toolbar>
</div>
