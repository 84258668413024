/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { UpdateTermsAndConditionsDto } from '../../models/update-terms-and-conditions-dto';
import { UpdateTermsAndConditionsResponse } from '../../models/update-terms-and-conditions-response';

export interface UpdateTermsAndConditions$Params {
      body: UpdateTermsAndConditionsDto
}

export function updateTermsAndConditions(http: HttpClient, rootUrl: string, params: UpdateTermsAndConditions$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateTermsAndConditionsResponse>> {
  const rb = new RequestBuilder(rootUrl, updateTermsAndConditions.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<UpdateTermsAndConditionsResponse>;
    })
  );
}

updateTermsAndConditions.PATH = '/api/v2/terms-and-conditions';
