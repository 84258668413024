import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { SystemService } from '@shure/cloud/device-management/devices/data-access';
import { ConnectInfoProviderValue } from '@shure/cloud/shared/apollo';

import { environment } from '../../environments/environment';

const localStorageKeyPrefix = 'shure.sysapi';
type LocalStorageKey = 'url' | 'wsUrl' | 'apiKey' | 'useWsTransport';

@Injectable()
export class CloudSystemService implements SystemService {
	/**
	 * Get System API connection info. Any values that have been set in local storage supersede the
	 * corresponding values from the environment.
	 */
	public getSysApiConnectInfo(): Observable<ConnectInfoProviderValue> {
		const url = environment.sysApi?.url ?? 'missing_from_env';
		const wsUrl = environment.sysApi?.wsUrl;
		const apiKey = environment.sysApi?.apiKey ?? 'missing_from_env';

		const useWsTransportEnvValue = environment.sysApi?.useWsTransport;
		const useWsTransportLocalStorageValue = this.getValueFromLocalStorage('useWsTransport');

		return of({
			url: this.getValueFromLocalStorage('url') ?? url,
			wsUrl: this.getValueFromLocalStorage('wsUrl') ?? wsUrl,
			apiKey: this.getValueFromLocalStorage('apiKey') ?? apiKey,
			useWsTransport:
				useWsTransportLocalStorageValue !== null
					? this.convertToBoolean(useWsTransportLocalStorageValue)
					: useWsTransportEnvValue
		});
	}

	public isSystemReady(): Observable<boolean> {
		return of(true);
	}

	private getValueFromLocalStorage(key: LocalStorageKey): string | null {
		const prefixedKey = `${localStorageKeyPrefix}.${key}`;
		return window.localStorage.getItem(prefixedKey);
	}

	private convertToBoolean(value: string): boolean {
		return value.toLowerCase() === 'true' || value === '1';
	}
}
