import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map, Observable } from 'rxjs';

import { OrganizationsStoreService } from '@shure/cloud/shared/services/organizations-store-service';

/**
 * maintenanceGuard - A route guard based on the current maintenace mode (MM) setting.
 *                    Assumes the MM information has been retrieved and stored in the
 *                    OrganizationsStoreService. This would typcially be done be done
 *                    during APP INIT. The logic is essentially:
 * 						 If in MM, and trying to navigate to a MM route, return true
 * 						 If in MM, and trying to navigate to a non-MM route, reroute and return false
 * 						 If not in MM, and trying to navigate to a MM route, reroute and return false
 * 						 If not in MM, and trying to navigate to a non-MM route, return true
 * @param tryingRouteType - a string indicating the type of route trying to be routed to...
 *                 either a 'maintenaceRoute' or a 'nonMaintenance' route.
 *               - this allows the guard to be used in a negative or positive manner
 *                 as a means to allow or prevent a route to be accessed.
 * @param reRouteTo - if returning false, where the guard reroutes to
 * @returns () => Observable<boolean>
 */
export function maintenanceGuard(
	tryingRouteType: 'maintenanceRoute' | 'nonMaintenanceRoute',
	reRouteTo: string
): CanActivateFn {
	return (_route, _state): Observable<boolean> => {
		const router = inject(Router);
		const orgStoreService = inject(OrganizationsStoreService);

		return orgStoreService.getMaintenance().pipe(
			map((maintenanceData) => {
				const maintenanceMode = maintenanceData?.maintenance ?? false;
				switch (tryingRouteType) {
					case 'maintenanceRoute':
						if (maintenanceMode) {
							return true;
						}
						router.navigate([reRouteTo]);
						return false;

					case 'nonMaintenanceRoute':
					default:
						if (maintenanceMode) {
							router.navigate([reRouteTo]);
							return false;
						}
						return true;
				}
			})
		);
	};
}
