import { APP_INITIALIZER, Provider } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { translocoInitializer } from './transloco-initializer';

/**
 * Provide transloco `APP_INITIALIZER`, which will await translations being loaded
 * before proceeding to the application.
 */
export function provideTranslocoInitializer(): Provider {
	return { provide: APP_INITIALIZER, useFactory: translocoInitializer, deps: [TranslocoService], multi: true };
}
