import { NgModule } from '@angular/core';
import { APOLLO_NAMED_OPTIONS, NamedOptions } from 'apollo-angular';

import {
	CloudDeviceApiService,
	DEVICE_DISCOVERY_CLAIMING_SERVICE,
	DetectionTokenApiService,
	DeviceClaimingApiService,
	DeviceClaimingDiscoveryApiService,
	DeviceDiscoveryApiService,
	DevicePropertyPanelApiService,
	DeviceStatusService,
	DeviceTagsApiService,
	InventoryDevicesApiService
} from '../api';

import { SysApiCloudDeviceApiService } from './sys-api-cloud-device-api.service';
import { SysApiConnectionService } from './sys-api-connection.service';
import { SysApiDetectionTokenApiService } from './sys-api-detection-token-api.service';
import { SysApiDeviceClaimingApiService } from './sys-api-device-claiming-api.service';
import { SysApiDeviceClaimingDiscoveryApiService } from './sys-api-device-claiming-discovery-api.service';
import { SysApiDeviceDiscoveryApiService } from './sys-api-device-discovery-api.service';
import { SysApiDevicePropertyPanelApiService } from './sys-api-device-property-panel-api.service';
import { SysApiDeviceTagApiService } from './sys-api-device-tag-api.service';
import { SysApiInventoryDevicesApiService } from './sys-api-inventory-devices-api.service';

@NgModule({
	providers: [
		DeviceStatusService,
		{
			provide: CloudDeviceApiService,
			useClass: SysApiCloudDeviceApiService
		},
		{
			provide: DetectionTokenApiService,
			useClass: SysApiDetectionTokenApiService
		},
		{
			provide: DeviceClaimingApiService,
			useClass: SysApiDeviceClaimingApiService
		},
		{
			provide: DeviceClaimingDiscoveryApiService,
			useClass: SysApiDeviceClaimingDiscoveryApiService
		},
		{
			provide: DeviceDiscoveryApiService,
			useClass: SysApiDeviceDiscoveryApiService
		},
		{
			provide: InventoryDevicesApiService,
			useClass: SysApiInventoryDevicesApiService
		},
		{
			provide: DevicePropertyPanelApiService,
			useClass: SysApiDevicePropertyPanelApiService
		},
		{
			provide: DEVICE_DISCOVERY_CLAIMING_SERVICE,
			useClass: SysApiDeviceClaimingDiscoveryApiService
		},
		{
			provide: DeviceTagsApiService,
			useClass: SysApiDeviceTagApiService
		},
		SysApiConnectionService,
		{
			provide: APOLLO_NAMED_OPTIONS,
			useFactory(sysApiConnectionService: SysApiConnectionService): NamedOptions {
				const options: NamedOptions = {
					sysApi: sysApiConnectionService.createApolloClientOptions()
				};
				return options;
			},
			deps: [SysApiConnectionService]
		}
	]
})
export class SysApiDevicesDataAccessModule {}
