export enum DeviceTransmitterSwitchBehavior {
	PushToMute = 'PUSH_TO_MUTE',
	PushToTalk = 'PUSH_TO_TALK',
	Toggle = 'TOGGLE',
	Disabled = 'DISABLED'
}

export enum DeviceTransmitterLightingBehavior {
	UnmuteSolidGreenMuteSolidRed = 'UNMUTE_SOLID_GREEN_MUTE_SOLID_RED',
	UnmuteSolidRedMuteOff = 'UNMUTE_SOLID_RED_MUTE_OFF',
	UnmuteSolidRedMuteFlashingRed = 'UNMUTE_SOLID_RED_MUTE_FLASHING_RED',
	ExternalControl = 'EXTERNAL_CONTROL'
}

export enum DeviceTransmitterInitialStateBehavior {
	Off = 'OFF',
	Active = 'ACTIVE'
}

export enum DeviceTransmitterMuteBehavior {
	ExternalMute = 'EXTERNAL_MUTE',
	LocalMuteAll = 'LOCAL_MUTE_ALL',
	LocalMuteIndividual = 'LOCAL_MUTE_INDIVIDUAL',
	LogicMute = 'LOGIC_MUTE',
	UsbHostSync = 'USB_HOST_SYNC'
}

export enum DeviceTransmitterUsbHostDisconnectMuteBehavior {
	LocalMuteIndividual = 'LOCAL_MUTE_INDIVIDUAL',
	LocalMuteAll = 'LOCAL_MUTE_ALL',
	AlwaysMute = 'ALWAYS_MUTE'
}
