import { Injectable } from '@angular/core';

import { InventoryDevice } from '@shure/cloud/shared/models/devices';
import { DeviceModel } from '@shure/shared/angular/data-access/system-api/models';

export interface HostDevice {
	id: string;
	model: DeviceModel;
}

@Injectable({ providedIn: 'root' })
export class DeviceRelationshipService {
	private proxyToHostMap = new Map<string, HostDevice>();

	public registerProxiedDevices(hostDevice: InventoryDevice): void {
		const proxiedDevices = hostDevice.features.proxiedDevices?.proxiedDevices ?? [];
		for (const proxiedDevice of proxiedDevices) {
			this.proxyToHostMap.set(proxiedDevice.id, hostDevice);
		}
	}

	public removeDevice(deviceId: string): void {
		// Remove all associations for this device ID, whether it is a proxy or host device
		for (const entry of this.proxyToHostMap.entries()) {
			if (entry[0] === deviceId || entry[1].id === deviceId) {
				this.proxyToHostMap.delete(entry[0]);
			}
		}
	}

	public getHostDevice(proxiedDeviceId: string): HostDevice | undefined {
		const hostDevice = this.proxyToHostMap.get(proxiedDeviceId);
		return hostDevice
			? {
					id: hostDevice.id,
					model: hostDevice.model
			  }
			: undefined;
	}
}
