import assertNever from 'assert-never';

import { LicenseSystemCommunicationState } from '@shure/cloud/shared/models/devices';

import { LicenseSystemCommunicationState as SysApiLicenseSystemCommunicationState } from '../graphql/generated/cloud-sys-api';

export function mapLicenseSystemCommunicationState(
	state: SysApiLicenseSystemCommunicationState
): LicenseSystemCommunicationState {
	switch (state) {
		case SysApiLicenseSystemCommunicationState.Failure_4XxClientError:
		case SysApiLicenseSystemCommunicationState.Failure_5XxServerError:
		case SysApiLicenseSystemCommunicationState.FailureTimeout:
		case SysApiLicenseSystemCommunicationState.FailureUnknown:
			return LicenseSystemCommunicationState.Failed;
		case SysApiLicenseSystemCommunicationState.NotAttempted:
			return LicenseSystemCommunicationState.NotAttemped;
		case SysApiLicenseSystemCommunicationState.Success:
			return LicenseSystemCommunicationState.Success;
		default:
			assertNever(state);
	}
}
