import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import {
	OrganizationsStoreService,
	MaintenanceConfigData
} from '@shure/cloud/shared/services/organizations-store-service';
import { APP_ENVIRONMENT, AppEnvironment } from '@shure/cloud/shared/utils/config';

function isEligibleDomain(eligibleDomains: string[]): boolean {
	for (const domain of eligibleDomains) {
		if (window.location.href.includes(domain)) {
			return true;
		}
	}
	return false;
}

/**
 * Method to fetch the contents of the mainteanceConfig.json file and store the  results
 * in the org store service. If the MM feature is not enalbed, the file is not retrieved.
 * @returns an observable to the MainteanceReponse data object.
 */
export function maintenanceModeInitializer(): () => Observable<MaintenanceConfigData> {
	const httpClient: HttpClient = inject(HttpClient);
	const orgStoreService = inject(OrganizationsStoreService);
	const appEnv: AppEnvironment = inject(APP_ENVIRONMENT);
	const nonMaintenanceModeResponse: MaintenanceConfigData = {
		maintenance: false,
		banner: false,
		startAt: '',
		endAt: '',
		message: ''
	};

	if (
		!appEnv.featureFlags?.maintenanceModeEnabled ||
		!isEligibleDomain(appEnv.maintenanceModeEligibleDomains ?? [])
	) {
		return () => {
			return of(nonMaintenanceModeResponse);
		};
	}

	const headers = new HttpHeaders({ Accept: 'application/json' });
	return () => {
		return httpClient.get(appEnv.maintenanceModeConfigFile ?? '', { headers }).pipe(
			map((result) => {
				orgStoreService.setMaintenance(<MaintenanceConfigData>result);
				return <MaintenanceConfigData>result;
			}),
			catchError(() => {
				orgStoreService.setMaintenance(nonMaintenanceModeResponse);
				return of(nonMaintenanceModeResponse);
			})
		);
	};
}
