import { Injectable } from '@angular/core';
import { ColDef, ColumnRowGroupChangedEvent, ColumnState, GridApi, SendToClipboardParams } from 'ag-grid-community';
import { BehaviorSubject, Observable } from 'rxjs';

import { macAddressComparator } from '../../utils/comparators';

export enum ExportFileTypes {
	CSV = 'csv',
	EXCEL = 'excel'
}

@Injectable({
	providedIn: 'root'
})
export class AgGridTableService {
	private disableExport$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	private locale = 'EN-US'; // To be made dynamic after multi-language support
	public isAnyRowGroupActive = 0;
	public columnState!: ColumnState[];

	/**
	 * Get disable export value
	 * @returns boolean: representation of disable export
	 */
	public getDisableExport$(): Observable<boolean> {
		return this.disableExport$;
	}

	/**
	 * Set disable export value
	 * @param disableExport: boolean representation of disableExport
	 */
	public setDisableExport(disableExport: boolean): void {
		this.disableExport$.next(disableExport);
	}

	/**
	 * Filters the table response based on the search text.
	 * @param filterValue - string representation of search text
	 */
	public applyFilter(gridApi: GridApi, filterValue: string): void {
		gridApi.updateGridOptions({ quickFilterText: filterValue });
		gridApi.onFilterChanged();
		if (gridApi.getModel().getRowCount() === 0) {
			gridApi.showNoRowsOverlay();
		} else {
			gridApi.hideOverlay();
			this.setDisableExport(true);
		}
	}

	/**
	 * sets the column order to the initial order after row group deletion
	 **/
	public onColumnRowGroupChanged(gridApi: GridApi, event: ColumnRowGroupChangedEvent): void {
		let activeRowGroupCount = 0;
		if (event.column) {
			activeRowGroupCount = event.column.isRowGroupActive() ? 1 : 0;
		} else if (event.columns && event.columns.length) {
			activeRowGroupCount = event.columns.filter((column) => column.isRowGroupActive()).length;
		}

		if (event.api) {
			// Get the current active row group columns
			const rowGroupColumns = event.api.getRowGroupColumns();
			this.isAnyRowGroupActive = rowGroupColumns.length;
		} else {
			this.isAnyRowGroupActive = activeRowGroupCount;
		}

		if (this.isAnyRowGroupActive === 0) {
			// Reset the column state to the initial state if no row groups are active
			gridApi.applyColumnState({ state: this.columnState, applyOrder: true });
		}
	}

	/**
	 * Creates a column definition for a checkbox selection column with common defaults
	 */
	public createCheckboxColumn(options: ColDef): ColDef {
		return {
			headerCheckboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			checkboxSelection: true,
			suppressHeaderMenuButton: true,
			suppressColumnsToolPanel: true, // don't want this column showing up in menus
			suppressMovable: true,
			sortable: false,
			filter: false,
			resizable: false,
			minWidth: 42,
			maxWidth: 42,
			pinned: 'left',
			lockPinned: true,
			lockPosition: true,
			flex: 0,
			...options
		};
	}

	/**
	 * Creates a column definition for a basic text column with sorting and filtering enabled by default
	 */
	public createActionColumn(options: ColDef): ColDef {
		return {
			field: 'action',
			headerName: '',
			sortable: false,
			filter: false,
			suppressMovable: true,
			lockPosition: 'right',
			enableRowGroup: false,
			lockPinned: true,
			singleClickEdit: true,
			suppressColumnsToolPanel: true,
			suppressHeaderMenuButton: true,
			maxWidth: 100,
			...options
		};
	}

	/**
	 * Creates a text column for a MAC address field
	 */
	public createMacAddressColumn(options: ColDef): ColDef {
		return {
			enableRowGroup: false,
			comparator: (a?: string, b?: string) => macAddressComparator(a, b),
			getQuickFilterText: (params) => params.value?.toLowerCase() ?? '',
			...options
		};
	}

	/**
	 * This function which overides the default behaviour of quickFilter
	 * It is called for every single row to check if the search string is present in the row
	 * @param quickFilterParts - string array which contains parts of the search string split by space
	 * @param rowQuickFilterAggregateText - string formed by the concatenation of data in a row into a single string
	 */
	public quickFilterMatcher = (quickFilterParts: string[], rowQuickFilterAggregateText: string): boolean => {
		const collator = new Intl.Collator(this.locale, { sensitivity: 'accent' });
		const nodeData = rowQuickFilterAggregateText.split('\n');
		return quickFilterParts.every((quickFilterItem) =>
			nodeData.some(
				(item: string) =>
					collator.compare(item.toLowerCase(), quickFilterItem.toLowerCase()) === 0 ||
					item.toLowerCase().includes(quickFilterItem.toLowerCase())
			)
		);
	};

	/**
	 * This function which return filtered colDefs
	 * @param colDefs - ColDef array which contains all the column definitions.
	 * @param excludedFields - string  array  which contains column field names.
	 */
	public filterColumnDefs(colDefs: ColDef[], excludedFields: string[]): ColDef[] {
		colDefs = colDefs.filter((colDef: ColDef) =>
			colDef.field && excludedFields.includes(colDef.field) ? false : true
		);
		return colDefs;
	}

	/**
	 * Intercept clipboard copy to remove empty columns from excluded fields.
	 */
	public sendToClipboard(params: SendToClipboardParams): void {
		// Get column definitions to determine indices to exclude
		const colDefs = params.api.getAllDisplayedColumns().map((col) => col.getColDef());
		const excludedIndices = colDefs
			.map((colDef, index) => {
				const isExcluded = colDef.field === 'action' || colDef.checkboxSelection;
				return isExcluded ? index : -1;
			})
			.filter((index) => index !== -1);
		// Split data into rows and columns
		const rows = params.data.split('\n').map((row) => row.split('\t'));
		// Remove cells at excluded indices in each row
		if (rows.every((row) => row.length === 1)) {
			navigator.clipboard.writeText(params.data);
			return;
		}
		const filteredRows = rows.map((row) => row.filter((_cell, index) => !excludedIndices.includes(index)));

		// Reassemble filtered data into a format suitable for the clipboard
		const filteredData = filteredRows.map((row) => row.join('\t')).join('\n');
		navigator.clipboard.writeText(filteredData);
	}
}
